"use client"

import { useTheme } from "next-themes"
import Image from "next/image"
import React, { useEffect, useState } from "react"

const DynamicImage = ({
  defaultImage,
  darkImage,
  height,
  width,
  alt,
  className,
}) => {
  const { theme, resolvedTheme } = useTheme()
  const [imageUrl, setImageUrl] = useState(defaultImage)

  useEffect(() => {
    // Use resolvedTheme to handle system theme changes properly
    setImageUrl(resolvedTheme === "dark" ? darkImage : defaultImage)
  }, [resolvedTheme, defaultImage, darkImage])

  return (
    <Image
      src={imageUrl}
      height={height ? height : 100}
      width={width ? width : 100}
      alt={alt ? alt : "image"}
      className={className}
    />
  )
}

export default DynamicImage
