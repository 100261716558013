"use client"

import { ButtonMode } from "@/components/fusion/button-mode"
import DynamicImage from "@/components/fusion/dynamic-image"
import HiddenWhenAuth from "@/components/fusion/hidden-when-auth"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { cn } from "@/lib/utils"
import { signIn, signOut, useSession } from "next-auth/react"
import Link from "next/link"

const Navbar = () => {
  const { data: session, status } = useSession()
  const isAuthenticated = status === "authenticated"
  return (
    <>
      <HiddenWhenAuth>
        <nav className="w-full max-container mx-auto  padding-container py-5 flex justify-between items-center ">
          <Link href="/">
            <DynamicImage
              darkImage={"/images/logo-dark.svg"}
              defaultImage={"/images/logo-light.svg"}
              width={50}
              height={50}
              alt={"logo else amikom"}
            />
          </Link>
          <div className="flex  justify-end items-center gap-5 ">
            <ButtonMode />
            <div className=" gap-5 flex">
              {/* <Input
                placeholder="cari tugas saya"
                type="text"
                className="w-fit"
              /> */}
              <Link
                className={cn("block", { "hidden ": !isAuthenticated })}
                href={"/dashboard"}>
                <Button>Dashboard</Button>
              </Link>
              {session ? (
                <Button onClick={() => signOut()}>Keluar</Button>
              ) : (
                <Button onClick={() => signIn()}>Masuk</Button>
              )}
            </div>
            {status === "authenticated" && (
              <Avatar>
                <AvatarImage src={session?.foto_mhs} />
                <AvatarFallback>CN</AvatarFallback>
              </Avatar>
            )}
          </div>
        </nav>
      </HiddenWhenAuth>
    </>
  )
}

export default Navbar
