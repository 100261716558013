"use client"

import { disableNavWithFooter } from "@/lib/utils"
import { usePathname } from "next/navigation"
import React from "react"

const HiddenWhenAuth = ({ children }) => {
  const path = usePathname()
  return <>{!disableNavWithFooter.includes(path) && <>{children}</>}</>
}

export default HiddenWhenAuth
